/* ===========================
Our theme header
   =========================== */
header {
   cursor: none !important;
   h1,
   ul {
      font-family: "Arial", sans-serif;
      font-size: 28px;
      line-height: 40px;
      text-transform: uppercase;
      @media (max-width: $tablet) {
         font-size: 24px;
         line-height: 36px;
      }
   }
   .site-nav {
      padding-bottom: 35px;
      @media (max-width: $tablet) {
         padding-bottom: 24px;
      }
   }
   .single-slide {
      cursor: none !important;
      border-radius: 12px;
      overflow: hidden;
      margin: 24px 12px 0 12px;
   }
   [aria-current]:not([aria-current="false"]) {
      cursor: none;
      pointer-events: none;
      position: absolute;
      &:after {
         content: "";
         position: absolute;
         width: 100%;
         height: 100%;
         left: 0;
         top: 0;
         background: rgba(255, 0, 0, 1);
      }
   }

   .video-wrap {
      width: 250px;
      height: 250px;
      @media (max-width: $mobile) {
         width: 200px;
         height: 200px;
      }
      video {
         width: 100%;
         object-fit: cover;
      }
   }

   .carousel-image {
      display: block;
      height: 250px;
      min-width: 100px;
      width: auto;
      top: 0;
      @media (max-width: $mobile) {
         height: 200px;
      }
   }
}
#ball,
#cursor-text {
   position: absolute;
   transform: translate(-50%, -80%);
   pointer-events: none;
   opacity: 0;
   z-index: 999;
   font-weight: 600;
   color: red;
   display: grid;
   place-items: center;
   transition: opacity 150ms ease-in-out;
   font-size: 16px;
}
