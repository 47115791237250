.back {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: $transition;
    transform: scale(1);
    z-index: 99;
    text-align: center;
    font-family: "Arial", sans-serif;
    color: $red;
    font-size: 48px;
    &:hover {
        transform: scale(1.05);
        color: $red;
    }
}
.sticky-title {
    position: sticky;
    top: 10px;
    z-index: 99;
    margin-left: 4px;
    @media (max-width: $tablet) {
        top: 16px;
        margin-left: 2px;
    }
}
