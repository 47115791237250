.block-type-text {
    //background: #000;
    padding: 3px;
}
h1,
h2,
h3,
h4,
h5,
.home-content {
    color: $red;
}
p {
    font-size: 4vw;
    color: $red;
    line-height: 1.1;
    @media (max-width: 1100px) {
        font-size: 4.5vw;
    }
    @media (max-width: $tablet) {
        font-size: 33px;
        line-height: 36px;
    }
}
/* .home-content p {
    font-size: 4vw;
    color: $red;
    line-height: 1.1;
    @media (max-width: 1100px) {
        font-size: 4.5vw;
        line-height: 1;
    }
    @media (max-width: $tablet) {
        font-size: 33px;
    }
}*/
