//
$red: #ff0000;
$yellow: #00ff1d;
$blue: #0014ff;

$largeFont: 6.15vw;

$transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

$mobile: 560px;
$tablet: 769px;
