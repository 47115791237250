/* ===========================
Default styles
   =========================== */
body {
    background: #fff;
    padding-bottom: 180px;
    .carousel {
        display: flex;
        .single-slide,
        img {
            min-width: 250px;
        }
        &.flickity-enabled {
            display: block;
        }
    }
    .block {
        margin-bottom: 35px;
        @media (max-width: $tablet) {
            margin-bottom: 24px;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    .page-meta h1 {
        text-transform: uppercase;
        @media (max-width: $tablet) {
            font-size: 24px;
        }
    }
    .subpage-h1 {
        margin-bottom: 35px;
        text-transform: uppercase;
        @media (max-width: $tablet) {
            font-size: 24px;
            margin-bottom: 24px;
        }
    }
    .contacts {
        padding-bottom: 35px;
        @media (max-width: $tablet) {
            padding-bottom: 24px;
        }
        a {
            color: $red;
            font-family: "Arial", "sans-serif";
            text-transform: uppercase;
            display: block;
            line-height: 1.2;
            @media (max-width: $tablet) {
                font-size: 24px;
            }
        }
    }
    .section--content {
        > .container:last-of-type {
            padding-bottom: 100vh;
        }
    }
}
.transition-fade {
    transition: 1s;
    filter: blur(0);
    img {
        transition: 1s;
        opacity: 1;
    }
}

html {
    &.is-changing {
        pointer-events: none !important;
    }
    .transition-fade {
        filter: blur(16px);
    }
    &.is-animating .transition-fade {
        filter: blur(16px) !important;
        img {
            opacity: 0;
        }
    }
    &.swup-enabled .transition-fade,
    &.is-rendering .transition-fade {
        filter: blur(0);

        img {
            opacity: 1;
        }
    }
}
